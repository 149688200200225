import dashboard from './dashboard';
import program from './program';
import pages from './pages';
import utilities from './utilities';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    //items: [dashboard, pages, utilities, other]
    //old code
    // items: [dashboard, utilities]
    //new changes
    items: [dashboard, program]
};

export default menuItems;
