import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Typography
} from '@mui/material';
import PlayAnime from '../../assets/images/PlayAnime3.gif';
import PauseImg from '../../assets/images/Pause2.png';
import { useAudioPlayer } from 'react-use-audio-player';

import MessageIconSvg from './MessageIcon';
import { MessageLeft, MessageRight } from './Message';
import { TextInput } from './TextInput';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import apiCall from '../../common/apiCall';
import Pusher from 'pusher-js';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            width: '80%',

            height: '80%',
            maxWidth: '600px',
            maxHeight: '500px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'relative'
        },
        paper2: {
            width: '80%',

            maxWidth: '500px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'relative'
        },
        container: {
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        messagesBody: {
            width: 'calc( 100% - 20px )',
            margin: 10,
            overflowY: 'scroll',
            height: 'calc( 80% - 80px )'
        },
        wrapForm: {
            display: 'flex',
            justifyContent: 'center',
            width: '95%',
            margin: `${theme.spacing(0)} auto`
        }
    })
);
const AudioPlayer = ({ audioSource, onStopProgram }) => {

    const hasAudioSource = audioSource !== "" && audioSource !== null && audioSource !== undefined
    console.log('hasAudioSource', hasAudioSource);

    const classes = useStyles();
    const { togglePlayPause, ready, loading, playing } = useAudioPlayer({
        src: hasAudioSource ? 'https://stream.insharp.life/program.mp3' : 'https://stream.insharp.life/listen.mp3',
        autoplay: true,
        html5: true,
        format: ['mp3'],
        onend: () => onStopProgram()
    });

    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('@insharptechnologies.com');
    const [isClose, setIsClose] = React.useState(true);
    const [email, setEmail] = React.useState('');
    const [fullName, setFullName] = useState('');
    const [showToken, setShowToken] = useState(false);
    const [token, setToken] = useState('');
    const [sr, setSr] = useState(false);
    const [chat, setChat] = useState([]);
    const [msg, setMsg] = useState('');
    const [isTokenValid, setIsTokenValid] = useState(false);
    const [state, setState] = useState({
        listener: '',
        song: '',
        artist: '',
        songType: '',
        comments: ''
    });

    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [chat]);

    const FetchSongRequest = async () => {
        try {
            let listener = atob(localStorage.getItem('authUser')).split('$');
            const url = `/chat/listener/${listener[0]}`;
            const method = 'GET';
            const response = await apiCall(url, method);
            if (response.success) {
                setChat(response.data);
                scrollToBottom();
            } else {
                setChat([]);
            }
        } catch (error) {
            console.log('Song Request Error: ', error.message);
        }
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(
            // @ts-expect-error autofill of arbitrary value is not handled.
            event.target.value
        );
    };

    const handleSongTypeChange = (event) => {
        setState({ ...state, songType: event.target.value });
    };

    useEffect(() => {
        console.log(open);
    }, [open]);

    const handleOpenClick = (e) => {
        if (e.target.innerText == 'CLOSE' || e.target.innerText == 'Close') {
            setOpen(false);
        } else {
            setOpen(true);
        }
    };

    const handleSave = async () => {
        try {
            if (!isValidEmail) {
                return;
            }
            const url = '/listener/register';
            const method = 'POST';
            var raw = {
                // old code
                // email: email + maxWidth,
                // new code
                email: maxWidth === 'other' ? email : email + maxWidth,
                token: fullName
            };
            console.log(raw);
            //window.location.href = '/dashboard/default';
            const response = await apiCall(url, method, raw);
            Promise.resolve(response).then((value) => {
                if (value.success === true) {
                    setShowToken(true);
                } else {
                    alert('Please try Again !');
                }
            });
        } catch (error) {
            console.log('registor Error: ', error.message);
        }
    };

    const sendSongRequest = async () => {
        try {
            const url = '/songrequest';
            const method = 'POST';
            let listener = atob(localStorage.getItem('authUser')).split('$');
            let raw = { ...state, listener: listener[0] };
            const response = await apiCall(url, method, raw);
            if (response.success) {
                setSr(false);
                setState({
                    listener: '',
                    song: '',
                    artist: '',
                    songType: '',
                    comments: ''
                });
                scrollToBottom();
            } else {
                alert('Request Failed!');
            }
        } catch (error) {
            console.log('Validate Error: ', error.message);
        }
    };

    const handleTokenSave = async () => {
        try {
            const url = '/listener/validate';
            const method = 'POST';
            var raw = {
                email: email + maxWidth,
                token: token
            };
            //window.location.href = '/dashboard/default';
            const response = await apiCall(url, method, raw);
            Promise.resolve(response).then((value) => {
                if (value.success === true) {
                    let res = value.data;
                    console.log('user data', res);
                    let user = btoa(res.email + '$' + res.token);
                    localStorage.setItem('authUser', user);
                    setIsTokenValid(true);
                    if (res.email) {
                        addActiveUser(res.email);
                    }
                } else {
                    alert('Invalid Token');
                }
            });
        } catch (error) {
            console.log('Validate Error: ', error.message);
        }
    };

    const onSendClick = async () => {
        try {
            let listener = atob(localStorage.getItem('authUser')).split('$');
            if (msg.length < 1) return;
            const url = '/chat';
            const method = 'POST';
            var raw = {
                createDate: listener[0],
                sender: 0,
                type: 2,
                status: 1,
                message: msg
            };
            const response = await apiCall(url, method, raw);
            if (response.success) {
                setMsg('');
            } else {
                alert('Send failed');
            }
        } catch (error) {
            console.log('Validate Error: ', error.message);
        }
        setMsg('');
    };

    const Chat = (e) => {
        setIsClose(false);
        handleOpenClick(e);
    };

    // new code
    const [isValidEmail, setIsValidEmail] = useState(true);

    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setEmail(newEmail);

        if (maxWidth === 'other') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setIsValidEmail(emailRegex.test(newEmail));
        }
    };

    useEffect(() => {
        FetchSongRequest();

        var pusher = new Pusher('b77c67885d09865ef4ac', {
            cluster: 'ap2'
        });

        var channel = pusher.subscribe('my-channel');

        channel.bind('my-event', (data) => {
            console.log('Received message chat:', data.message);
            if (data.message === 'refreshChat' || data.message === 'refreshSong') {
                FetchSongRequest();
            } //:ns
        });

        pusher.connection.bind('connected', () => {
            // console.log('Connected to Pusher');
        });

        pusher.connection.bind('error', (err) => {
            // console.error('Pusher connection error:', err);
        });

        return () => {
            pusher.unsubscribe('my-channel');
        };
    }, []);

    useEffect(() => {
        const authUser = localStorage.getItem('authUser');

        if (authUser) {
            let listener = atob(localStorage.getItem('authUser')).split('$');
            const listerEmail = listener[0];
            addActiveUser(listerEmail);
        }
        scrollToBottom();
    }, []);

    const addActiveUser = async (listerEmail) => {
        try {
            const url = '/activeUser/create-ActiveUser';
            const method = 'POST';
            var data = {
                email: listerEmail
            };

            const response = await apiCall(url, method, data);
            Promise.resolve(response).then((value) => {
                if (value.success === true) {
                    console.log(value.uuid);
                } else {
                    console.log('failed');
                }
            });
        } catch (error) {
            console.log('Validate Error: ', error.message);
        }
    };

    const updateActiveUser = async (listerEmail) => {
        try {
            const url = '/activeUser/update-ActiveUser-Status';
            const method = 'POST';
            var data = {
                email: listerEmail,
                status: 0
            };

            const response = await apiCall(url, method, data);
            Promise.resolve(response).then((value) => {
                if (value.success === true) {
                    console.log(value);
                } else {
                    console.log('failed');
                }
            });
        } catch (error) {
            console.log('Validate Error: ', error.message);
        }
    };
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            // console.log('Tab is closing');
            const authUser = localStorage.getItem('authUser');

            if (authUser) {
                let listener = atob(localStorage.getItem('authUser')).split('$');
                const listerEmail = listener[0];
                updateActiveUser(listerEmail);
            }
        };
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return (
        <Grid container direction="row" justifyContent="space-between">
            {ready ? (
                // eslint-disable-line no-use-before-define
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                <img
                    src={playing ? PlayAnime : PauseImg}
                    alt={'banner'}
                    style={{ width: '80px', height: '80px', cursor: 'pointer' }}
                    onClick={togglePlayPause}
                />
            ) : (
                <Typography variant={'body2'} style={{ width: '80px' }}>
                    Loading...
                </Typography>
            )}{' '}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
            <h3
                className={'tap-text'}
                style={{ marginLeft: '20px', marginRight: '100px', cursor: 'pointer', width: '100px' }}
                onClick={togglePlayPause}
            >
                {playing ? 'Tap to pause' : 'Tap to play'}
            </h3>{' '}
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div style={{ cursor: 'pointer' }} onClick={Chat}>
                <MessageIconSvg />
                <Dialog fullWidth={fullWidth} maxWidth={'sm'} open={open} onClose={handleOpenClick}>
                    <DialogTitle>Live Chat</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Tunein Live chat </DialogContentText>
                        <div
                            noValidate
                            component="form"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                m: 'auto',
                                width: 'fit-content'
                            }}
                        >
                            {localStorage.getItem('authUser') || isTokenValid ? (
                                <div className={classes.container}>
                                    <Paper className={classes.paper} zDepth={2}>
                                        {sr ? (
                                            <div>
                                                <div className={classes.wrapForm}>
                                                    <TextField
                                                        id="standard-text"
                                                        label="Song"
                                                        placeholder="Enter Song"
                                                        className={classes.wrapText}
                                                        value={state.song}
                                                        onChange={(e) => {
                                                            setState({ ...state, song: e.target.value });
                                                        }}
                                                        fullWidth={true}
                                                    //margin="normal"
                                                    />
                                                </div>
                                                <form className={classes.wrapForm} noValidate autoComplete="off">
                                                    <TextField
                                                        id="standard-text"
                                                        label="Artist"
                                                        placeholder="Enter Artist"
                                                        className={classes.wrapText}
                                                        value={state.artist}
                                                        onChange={(e) => {
                                                            setState({ ...state, artist: e.target.value });
                                                        }}
                                                        fullWidth={true}
                                                    //margin="normal"
                                                    />
                                                </form>
                                                <br />
                                                <form className={classes.wrapForm} noValidate autoComplete="off">
                                                    <FormControl fullWidth={true}>
                                                        <InputLabel id="songType">Song Type</InputLabel>
                                                        <Select
                                                            labelId="songType"
                                                            id="songType"
                                                            value={state.songType}
                                                            label="Song Type"
                                                            onChange={handleSongTypeChange}
                                                            style={{ paddingLeft: '10px' }}
                                                        >
                                                            <MenuItem
                                                                style={{ paddingLeft: '25%', paddingRight: '25%', fontSize: '14px' }}
                                                                value="Original"
                                                            >
                                                                &nbsp;&nbsp;Original Song&nbsp;
                                                            </MenuItem>
                                                            <br />
                                                            <MenuItem
                                                                style={{
                                                                    paddingLeft: '30%',
                                                                    paddingRight: '30%',
                                                                    fontSize: '14px',
                                                                    marginTop: '2px'
                                                                }}
                                                                value="Cover"
                                                            >
                                                                &nbsp;&nbsp;Cover Song&nbsp;
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </form>
                                                <br />
                                                <form className={classes.wrapForm} noValidate autoComplete="off">
                                                    <TextField
                                                        id="standard-text"
                                                        label="Comments"
                                                        placeholder="Comments"
                                                        className={classes.wrapText}
                                                        value={state.comments}
                                                        onChange={(e) => {
                                                            setState({ ...state, comments: e.target.value });
                                                        }}
                                                        fullWidth={true}
                                                    //margin="normal"
                                                    />
                                                </form>
                                                <br />
                                                <Button
                                                    style={{ paddingLeft: '40%', paddingRight: '40%' }}
                                                    color="success"
                                                    onClick={sendSongRequest}
                                                >
                                                    Send
                                                </Button>
                                            </div>
                                        ) : (
                                            <Paper id="style-1" className={classes.messagesBody}>
                                                {chat.map((r) =>
                                                    r.side === 'Left' ? (
                                                        <MessageLeft
                                                            message={r.msg}
                                                            timestamp={r.time}
                                                            photoURL=""
                                                            displayName={r.user}
                                                            avatarDisp={false}
                                                        />
                                                    ) : (
                                                        <MessageRight
                                                            message={r.msg}
                                                            timestamp={r.time}
                                                            photoURL=""
                                                            displayName={r.user}
                                                            avatarDisp={true}
                                                        />
                                                    )
                                                )}
                                                <div ref={messagesEndRef} />
                                            </Paper>
                                        )}
                                        <TextInput
                                            sendDisabled={sr}
                                            onSongClick={() => {
                                                setSr(!sr);
                                            }}
                                            onSendClick={onSendClick}
                                            onMsgChange={(e) => {
                                                setMsg(e.target.value);
                                            }}
                                            value={msg}
                                        />
                                    </Paper>
                                </div>
                            ) : (
                                <div>
                                    {showToken == true ? (
                                        <Paper className={classes.paper} zDepth={2}>
                                            <form className={classes.wrapForm} noValidate autoComplete="off">
                                                <TextField
                                                    id="standard-text"
                                                    label="Token"
                                                    placeholder="Enter token from mail"
                                                    className={classes.wrapText}
                                                    value={token}
                                                    onChange={(e) => {
                                                        setToken(e.target.value);
                                                    }}
                                                    fullWidth={true}
                                                />
                                            </form>
                                            <br />
                                            <Button color="success" onClick={handleTokenSave}>
                                                Validate
                                            </Button>
                                        </Paper>
                                    ) : (
                                        <Paper className={classes.paper} zDepth={2}>
                                            <form className={classes.wrapForm} noValidate autoComplete="off">
                                                <TextField
                                                    id="standard-text"
                                                    label="Email"
                                                    className={classes.wrapText}
                                                    value={email}
                                                    onChange={handleEmailChange}
                                                    fullWidth={true}
                                                />
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-select-small">Company</InputLabel>
                                                    <Select
                                                        labelId="demo-select-small"
                                                        id="demo-select-small"
                                                        value={maxWidth}
                                                        label="Company"
                                                        onChange={handleMaxWidthChange}
                                                    >
                                                        <MenuItem value="@insharptechnologies.com">&nbsp;Insharp&nbsp;</MenuItem>
                                                        <MenuItem value="@bizmo.info">&nbsp;Bizmo&nbsp;</MenuItem>
                                                        <MenuItem value="@gmail.com">&nbsp;Gmail&nbsp;</MenuItem>
                                                        <MenuItem value="other">&nbsp;Other&nbsp;</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </form>
                                            <br />

                                            {!isValidEmail && <p style={{ color: 'red' }}>Please enter a valid email address.</p>}

                                            <InputLabel htmlFor="max-width">{maxWidth === 'other' ? email : email + maxWidth}</InputLabel>

                                            <br />
                                            <form className={classes.wrapForm} noValidate autoComplete="off">
                                                <TextField
                                                    id="standard-text"
                                                    label="Full Name"
                                                    placeholder="Jhone Doe"
                                                    className={classes.wrapText}
                                                    value={fullName}
                                                    onChange={(e) => {
                                                        setFullName(e.target.value);
                                                    }}
                                                    fullWidth={true}
                                                />
                                            </form>
                                            <br />
                                            <Button color="success" onClick={handleSave}>
                                                Login
                                            </Button>
                                        </Paper>
                                    )}
                                </div>
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleOpenClick}>Close</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Grid>
    );
};

export default AudioPlayer;

