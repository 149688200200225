import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Slide,
    Switch,
    Typography
} from '@mui/material';
import Logo from '../../common/Logo';
import Banner from '../../assets/images/mainBanner.jpeg';

import { AudioPlayerProvider } from 'react-use-audio-player';

import apiCall from '../../common/apiCall';
import Pusher from 'pusher-js';
import AudioPlayer from './AudioPlayer';

const Radio = () => {
    const [radioDetails, setRadioDetails] = useState();
    useEffect(() => {
        const url = 'https://34.125.49.210:8000/status-json.xsl';
        // const result = ApiCall(url, 'GET');
        // Promise.resolve(result).then((value) => {
        //     console.log(value);
        // });
    }, []);

    const [startTime, setStartTime] = useState('');
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [currentProgram, setCurrentProgram] = useState(null);
    const [programList, setProgramList] = useState([]);
    const [scheduledStartTime, setScheduledStartTime] = useState('');
    const [endTime, setEndTime] = useState('');

    const getTimeDifference = (startTime) => {
        // Convert startTime to milliseconds
        const start = new Date(startTime).getTime();

        // Get current time in milliseconds
        const now = new Date().getTime();

        // Calculate the difference in milliseconds
        const differenceInMillis = now - start;

        // Convert difference to seconds
        const seconds = Math.floor(differenceInMillis / 1000);

        // Calculate milliseconds
        const milliseconds = differenceInMillis % 1000;

        return seconds + milliseconds / 1000;
    };

    const formatTime = (time) => {
        const hour = parseInt(time.split(':')[0]);
        const minute = time.split(':')[1];
        const meridiem = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
        return `${formattedHour}:${minute} ${meridiem}`;
    };

    //ns
    const FetchCurrentlyPlayingProgram = async () => {
        try {
            const url = '/program/currently-playing';
            const method = 'POST';
            const response = await apiCall(url, method);
            console.log('currently playing', response);
            if (response.success === true) {
                console.log('current playing program', response.programs[0]);

                setCurrentProgram(response.programs[0])
                const startTime = response.programs[0].actualStartTime;

                // Get time difference
                const timeDifference = getTimeDifference(startTime);

                console.log('Time Difference:', timeDifference);

                setStartTime(timeDifference);
                const formattedStartTime = formatTime(response.programs[0].actualStartTime);
                const formattedEndTime = response.programs[0].endTime ? formatTime(response.programs[0].endTime) : null;

                setScheduledStartTime(formattedStartTime);
                setEndTime(formattedEndTime);
            }
        } catch (error) {
            console.log('song Request Error: ', error.message);
        }
    };

    const handleStopProgram = async () => {
        console.log('sound has ended!')
        const programID = currentProgram?.id
        try {
            const url = '/program/update-program-status';
            const method = 'POST';
            if (programID) {
                const data = {
                    Id: programID,
                    Status: 3 //stoped
                };
                await apiCall(url, method, data);
            }
        } catch (error) {
            console.log('Error: ', error.message);
        } finally {
            setCurrentProgram(null)
        }
    };

    useEffect(() => {
        FetchCurrentlyPlayingProgram();
        FetchPrograms();
    }, []);

    useEffect(() => {
        var pusher = new Pusher('b77c67885d09865ef4ac', {
            cluster: 'ap2'
        });

        var channel = pusher.subscribe('my-channel');

        channel.bind('my-event', (data) => {
            console.log('Received message chat:', data.message);
            if (data.message === 'START') {
                FetchCurrentlyPlayingProgram();
                handlePlay();
            } else if (data.message === 'STOP') {
                handleStop();
                //FetchCurrentlyPlayingProgram();
            } else if (data.message === 'PAUSE') {
                handlePause();
            } else if (data.message === 'PLAY') {
                handlePlay();
            }
        });

        pusher.connection.bind('connected', () => {
            // console.log('Connected to Pusher');
        });

        pusher.connection.bind('error', (err) => {
            // console.error('Pusher connection error:', err);
        });

        return () => {
            pusher.unsubscribe('my-channel');
        };
    }, []);

    const audioRef = useRef(null);

    // const handleTimeUpdate = (event) => {
    //     // console.log("current playing time", event.target.currentTime);
    //     // console.log("current playing audio's duration", event.target.duration);
    //     setCurrentTime(event.target.currentTime);
    //     setDuration(event.target.duration);
    // };



    const handlePlay = () => {
        setIsPlaying(true);
        if (audioRef) {
            audioRef.current?.play();
        }

        console.log('Audio is playing.');
    };

    const handlePause = () => {
        setIsPlaying(false);
        if (audioRef) {
            audioRef.current?.pause();
        }
        console.log('Audio is paused.');
    };

    const handleStop = () => {
        setIsPlaying(false);
        setCurrentProgram(null)
        console.log('Audio is stopped.');
    };


    // const handlePlayPause = () => {
    //     if (isPlaying) {
    //         audioRef.current.pause();
    //         console.log('handlePlayPause is pausing.');
    //     } else {
    //         audioRef.current.play();
    //         console.log('handlePlayPause is playing.');
    //     }
    //     setIsPlaying(!isPlaying);
    // };

    const FetchPrograms = async () => {
        try {
            const url = '/program';
            const method = 'POST';
            const response = await apiCall(url, method);
            console.log('API Response:', response);

            if (response.success === true) {
                const programList = response.programs.filter((program) => program.status === 0);
                console.log('Filtered program list:', programList);
                setProgramList(programList);
            } else {
                console.log('Error fetching data:', response.msg);
            }
        } catch (error) {
            console.log('song Request Error: ', error.message);
        }
    };

    return (
        <React.Fragment>
            <Grid container spacing={0.5}>
                <Grid
                    container
                    xs={12}
                    style={{
                        backgroundColor: '#000000'
                        //backgroundImage: 'radial-gradient(closest-side at 50% 50%, rgb(5 41 5), #000000, #000000)'
                    }}
                >
                    <Grid item sm={3} xs={0} md={3} lg={3}></Grid>
                    <Grid container sm={6} xs={12} md={6} lg={6} direction="row" justifyContent="center" style={{ marginTop: '100px' }}>
                        <div className={'oval-back'}></div>
                        <Logo />
                        <Typography variant="h3" className={'on-air'} style={{ width: '80%' }}>
                            Now on Air !
                        </Typography>
                        <img src={currentProgram !== null ? currentProgram?.photoPath : Banner} alt={'banner'} className={'banner'} />
                        <div className={'banner-overlow'}></div>
                        {currentProgram ? (
                            <>
                                <Typography variant="h3" className={'program-name prgram-title'}>
                                    {currentProgram.name ?? ""}
                                </Typography>
                                <Typography variant="body1" className={'program-time'}>
                                    {scheduledStartTime} {endTime && `- ${endTime}`}
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Typography variant="h3" className={'program-name prgram-title'}>
                                    LIVE MIX
                                </Typography>
                                <Typography variant="body1" className={'program-time'}>
                                    {/* {scheduledStartTime} {endTime && `- ${endTime}`} */}
                                </Typography>
                            </>
                        )}
                        <Typography variant="h3" className={'program-name prgram-title'}>
                            {currentProgram?.name ?? ""}
                        </Typography>
                        <Typography variant="body1" className={'program-time'}>
                            {scheduledStartTime} {endTime && `- ${endTime}`}
                        </Typography>

                        <Typography variant="body1" className={'upcome-text'}>
                            Upcoming Shows
                        </Typography>
                        <Grid container direction="row" justifyContent="center" className={'up-comming'}>
                            {programList.map((program, index) => (
                                <UpElement
                                    key={index}
                                    name={program.name}
                                    startTime={program.startTime} // Pass startTime prop
                                    endTime={program.endTime}
                                    imageUrl={program.photoPath} // Pass endTime prop
                                />
                            ))}
                        </Grid>

                        <div style={{ marginTop: '30px' }}>
                            <AudioPlayerProvider>
                                <AudioPlayer
                                    audioSource={currentProgram?.audioPath ?? null}
                                    onStopProgram={handleStopProgram}
                                />
                            </AudioPlayerProvider>
                        </div>
                    </Grid>
                    <Grid item sm={3} xs={0} md={3} lg={3}></Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default Radio;

const formatTime = (time) => {
    const hour = parseInt(time.split(':')[0]);
    const minute = time.split(':')[1];
    const meridiem = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    return `${formattedHour}:${minute} ${meridiem}`;
};

const UpElement = ({
    key,
    name,
    startTime, // Pass startTime prop
    endTime,
    imageUrl
}) => {
    const formattedStartTime = formatTime(startTime);
    const formattedEndTime = endTime ? formatTime(endTime) : null;

    return (
        <Grid container direction="row" justifyContent="center" style={{ marginTop: '30px' }}>
            <div>
                <img src={imageUrl} alt={'banner'} style={{ width: '80px', height: '80px', zIndex: '10' }} />
            </div>
            <div style={{ verticalAlign: 'middle', textAlign: 'left', paddingTop: '25px', paddingLeft: '10px' }}>
                <div className={'next-program-name'}>{name}</div>
                <div className={'next-program-time'}>
                    {formattedStartTime} {formattedEndTime && ` - ${formattedEndTime}`}
                </div>
            </div>
        </Grid>
    );
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


