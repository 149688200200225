// assets
import { IconRadio } from '@tabler/icons';

// constant
const icons = { IconRadio };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const program = {
    id: 'program',
    type: 'group',
    children: [
        {
            id: 'programs',
            title: 'Programs',
            type: 'item',
            url: '/dashboard/programs',
            icon: icons.IconRadio,
            breadcrumbs: false
        }
    ]
};

export default program;
