import React from 'react';

const apiCall = (url, method, body) => {
    const options = {
        method,
        headers: {
            'Content-Type': 'application/json'
        }
    };
    if (body) {
        options.body = JSON.stringify(body);
    }

    return fetch(url, options)
        .then((res) => res.json())
        .then((data) => data)
        .catch((err) => {
            throw err;
        });
};

export default apiCall;