import React from 'react';
const MessageIconSvg = () => {
    return (
        <svg width="40" height="42" viewBox="0 0 40 42" fill="none" xmlns="https://www.w3.org/2000/svg">
            <path
                d="M29.3333 15.3334H29.3566"
                stroke="url(#paint0_linear_11_258)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M20 15.3334H20.0233"
                stroke="url(#paint1_linear_11_258)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M10.6666 15.3334H10.69"
                stroke="url(#paint2_linear_11_258)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M8.33331 35.1667V41L20 29.3334"
                stroke="url(#paint3_linear_11_258)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M8.33331 29.3334H1.33331V1.33337H38.6666V6.00004M20 29.3334H38.6666V15.3334"
                stroke="url(#paint4_linear_11_258)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <defs>
                <linearGradient id="paint0_linear_11_258" x1="29.345" y1="15.3334" x2="29.345" y2="16.3334" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#57EBDE" />
                    <stop offset="1" stop-color="#AEFB2A" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_11_258"
                    x1="20.0117"
                    y1="15.3334"
                    x2="20.0117"
                    y2="16.3334"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#57EBDE" />
                    <stop offset="1" stop-color="#AEFB2A" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_11_258"
                    x1="10.6783"
                    y1="15.3334"
                    x2="10.6783"
                    y2="16.3334"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#57EBDE" />
                    <stop offset="1" stop-color="#AEFB2A" />
                </linearGradient>
                <linearGradient id="paint3_linear_11_258" x1="14.1666" y1="29.3334" x2="14.1666" y2="41" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#57EBDE" />
                    <stop offset="1" stop-color="#AEFB2A" />
                </linearGradient>
                <linearGradient id="paint4_linear_11_258" x1="20" y1="1.33337" x2="20" y2="29.3334" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#57EBDE" />
                    <stop offset="1" stop-color="#AEFB2A" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default MessageIconSvg;
